<template>
	<div>
		<b-modal
			id="modal-add-notification"
			cancel-variant="outline-secondary"
			:ok-title="$t('Add')"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			centered
			:title="$t('Add notification')"
			@ok="confirmAdd"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="addNotificationModal">
				<b-form
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationForm"
				>
                    <!-- Field: Name -->
<!--					<b-form-group-->
<!--						label-for="name"-->
<!--					>-->
<!--						<label class="mb-1">{{ $t('Name') }}</label>-->
<!--						<validation-provider-->
<!--							#default="{ errors }"-->
<!--							name="notificationName"-->
<!--						>-->
<!--							<b-form-input-->
<!--								id="name"-->
<!--								v-model="notification.name"-->
<!--								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--							/>-->
<!--							<small class="text-danger">{{ errors[0] }}</small>-->
<!--						</validation-provider>-->
<!--					</b-form-group>-->
<!--					<b-form-group-->
<!--						label-for="category"-->
<!--					>-->
<!--						<label class="mb-1">{{ $t('Category') }}</label>-->
<!--						<validation-provider-->
<!--							#default="{ errors }"-->
<!--							name="category"-->
<!--						>-->
<!--							<v-select-->
<!--								v-model="notification.category"-->
<!--								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--								:options="categoryOptions"-->
<!--								label="name"-->
<!--								:reduce="val => val.id"-->
<!--								input-id="category"-->
<!--							/>-->
<!--							<small class="text-danger">{{ errors[0] }}</small>-->
<!--						</validation-provider>-->
<!--					</b-form-group>-->

<!--					<b-form-group-->
<!--						label-for="type"-->
<!--					>-->
<!--						<label class="mb-1">{{ $t('Type') }}</label>-->
<!--						<validation-provider-->
<!--							#default="{ errors }"-->
<!--							name="type"-->
<!--						>-->
<!--							<v-select-->
<!--								v-model="notification.type"-->
<!--								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--								:options="typeOptions"-->
<!--								label="name"-->
<!--								:reduce="val => val.id"-->
<!--								input-id="type"-->
<!--							/>-->
<!--							<small class="text-danger">{{ errors[0] }}</small>-->
<!--						</validation-provider>-->
<!--					</b-form-group>-->

                    <b-form-group
						label-for="content"
					>
						<label class="mb-1">{{ $t('Content') }}</label>
                        <b-tabs>
                          <validation-provider
                              #default="{ errors }"
                              name="content"
                              rules="required"
                          >
                            <b-form-textarea
                                v-model="notification.content"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                input-id="content"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                          <!--                            <b-tab-->
<!--                                title="English"-->
<!--                            >-->
<!--                                <validation-provider-->
<!--                                    #default="{ errors }"-->
<!--                                    name="contentEn"-->
<!--                                >-->
<!--                                    <b-form-textarea-->
<!--                                        v-model="notification.contentEn"-->
<!--                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                                        input-id="contentEn"-->
<!--                                    />-->
<!--                                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                                </validation-provider>        -->
<!--                            </b-tab>-->
                        </b-tabs>
						
					</b-form-group>

				</b-form>
			</validation-observer>

		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default {
	components: {
		BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
	},
	data() {
		return {
			notification: {
                name: '',
                content: '',
                contentEn: '',
                category: '',
                type: ''
			},
            categoryOptions: [
                { id: 1, name: 'General' },
                { id: 2, name: 'Betting' },
                { id: 3, name: 'Promotion'},
                { id: 4, name: 'Maintenance' },
                { id: 5, name: 'Payment Related' },
            ],
            typeOptions: [
                { id: 1, name: 'Public' },
                { id: 2, name: 'Members' },
            ],
		}
	},
	methods: {
		validationForm() {
			this.$refs.addNotificationModal.validate().then(success => {
				if (success) {
					this.addNotification(this.notification)
				}
			})
		},
		async addNotification(notificationData) {
			await this.$store.dispatch('frontend/addNotification', notificationData)
				.then(response => {
					if (response.status === 200) {
						if (response.data.code == '00') {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'success',
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
                            this.$emit('refetch-data')
							this.$nextTick(() => {
								setTimeout(() => {
									this.$bvModal.hide('modal-add-notification')
									this.resetModal()
								}, '1000')
							})
							this.$emit('refetch-data')
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					}
				})
		},
		resetModal() {
		},
		confirmAdd(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationForm()
		}
	},
	setup() {

		return {
		}
	},
}
</script>